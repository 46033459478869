import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.pt-br"

const IndexPage = ({ location, data }) => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }
    if (typeof window !== "undefined") {
      handleResize()
      window.addEventListener("resize", () => {
        handleResize()
      })
    }
  }, [])
  return (
    <>
      <Metatags
        title="Multilógica"
        description="Fornecedor de maquinário para colchões"
        url="https://newsite.multilogica.ind.br/pt-br/"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchão, máquina de costura, mammut, beckmann sew"
        language="pt-br"
      />
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        {/* <GatsbyImage
          image={getImage(data.bannerTop)}
          backgroundColor={false}
          alt="máquinas para colchões"
        /> */}
        {mobile ? (
          <GatsbyImage
            image={getImage(data.elektroteksMobile)}
            backgroundColor={false}
            alt="elektroteks-acquires-mammut"
          />
        ) : (
          <GatsbyImage
            image={getImage(data.elektroteks)}
            backgroundColor={false}
            alt="elektroteks-acquires-mammut"
          />
        )}
        <div className="h-8" />
        <div className=" text-center">
          <Link to="elektroteks-acquires-mammut">
            <button className="text-mi-yellow text-xl bg-mi-blue py-4 px-8 rounded-full hover:bg-mi-light-blue">
              Mais informação
            </button>
          </Link>
        </div>
        <div className="h-24" />
        <div className="max-w-2xl text-center mx-2 md:mx-auto">
          <div className="text-mi-blue text-3xl font-semibold">
            Fornecedor de maquinário para colchões
          </div>
          <div className="h-2" />
          <div className="text-mi-blue text-xl">
            Equipamentos de alta qualidade e performance que combinam com a
            experiência e a excelência no atendimento.
          </div>
        </div>
        <div className="h-24" />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-24" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-3xl font-semibold">
              Nossas atividades
            </div>
          </div>
          <div className="h-24" />
          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="col-span-1" />
            <div className="col-span-10 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-12 mx-auto">
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconMatress)}
                    backgroundColor={false}
                    alt="Fornecimento de máquinas para colchões"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Fornecimento de máquinas para colchões
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconMaintenance)}
                    backgroundColor={false}
                    alt="Vendas, instalação e reparo de máquinas"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Vendas, instalação e reparo de máquinas
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconTraining)}
                    backgroundColor={false}
                    alt="Treinamento para operadores"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Treinamento para operadores
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-6">
                  <GatsbyImage
                    image={getImage(data.iconDiagnosis)}
                    backgroundColor={false}
                    alt="Diagnóstico, identificação e vendas de peças de reposição"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Diagnóstico, identificação e vendas de peças de reposição
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-6">
                  <GatsbyImage
                    image={getImage(data.iconCustom)}
                    backgroundColor={false}
                    alt="Soluções personalizadas e adequação de equipamentos"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Soluções personalizadas e adequação de equipamentos
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconPatterns)}
                    backgroundColor={false}
                    alt="Desenvolvimento de desenhos"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Desenvolvimento de desenhos
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-24" />
        </div>
      </div>
      <div className="w-full">
        <div className="h-24" />
        <div className="max-w-2xl mx-2 md:mx-auto text-center">
          <div className="text-mi-blue text-3xl font-semibold">
            Representante no Brasil para as máquinas:
          </div>
        </div>
        <div className="h-24" />
        <div className="grid grid-cols-1 md:grid-cols-12 mx-2">
          <div className="col-span-1" />
          <div className="col-span-10 grid grid-cols-1 gap-x-8 gap-y-12 mx-auto">
            <div className=" mx-auto ">
              <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="col-span-3">
                  <GatsbyImage
                    image={getImage(data.logoMammut)}
                    backgroundColor={false}
                    alt="Mammut"
                    className="mr-10"
                  />
                </div>
                <div className=" text-xl col-span-8">
                  <p>
                    Nos últimos 140 anos, a Companhia Stutznäcker tem sido
                    especializada na fabricação de acolchoadoras de alta
                    performance. Devido à esta confiabilidade e alta
                    performance, as máquinas MAMMUT são utilzadas em um
                    incontável número de fábricas e particularmente em
                    companhias onde o cumprimento de prazos de entrega e a
                    qualidade do produto final tem a mais alta prioridade.
                  </p>
                </div>
                <div className="col-span-1 place-self-end">
                  <GatsbyImage
                    image={getImage(data.madeInGermany)}
                    backgroundColor={false}
                    alt="Made in Germany"
                  />
                </div>
              </div>
              <div className="h-8" />
              <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="col-span-3">
                  <GatsbyImage
                    image={getImage(data.logoBeckmann)}
                    backgroundColor={false}
                    alt="Beckmann"
                  />
                </div>
                <div className=" text-xl col-span-8">
                  <p>
                    Desde 1990 produz máquinas para produção de colchões e
                    faixas para colchões. Compromisso com normas de qualidade, a
                    entrega absoluta de cada um de seus colaboradores, o serviço
                    impecável, assim como cumprimento dos prazos de entrega, tem
                    feito com que os clientes à valorizem em nível mundial.
                  </p>
                </div>
                <div className="col-span-1 place-self-end">
                  <GatsbyImage
                    image={getImage(data.madeInGermany)}
                    backgroundColor={false}
                    alt="Made in Germany"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="h-24" />
        <div className="h-24" />
        <div className="bg-mi-gray">
          <div className="h-10" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-mi-blue text-2xl">
              Entre em contato com a nossa equipe para mais informações
            </div>
            <div className="h-2" />
            <Link to="contato">
              <button className="text-mi-yellow text-xl bg-mi-blue py-4 px-8 rounded-full hover:bg-mi-light-blue">
                Entrar em contato
              </button>
            </Link>
          </div>
          <div className="h-10" />
        </div>
        <div className="h-24" />
        <GatsbyImage
          image={getImage(data.bannerBottom)}
          backgroundColor={false}
          alt="máquinas para colchões"
        />
      </div>
      <Footer />
    </>
  )
}
export default IndexPage

export const indexPT = graphql`
  query {
    iconMatress: file(relativePath: { eq: "icons/Icone_Multi_Colchao.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconPatterns: file(relativePath: { eq: "icons/Icone_Multi_Desenhos.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconDiagnosis: file(
      relativePath: { eq: "icons/Icone_Multi_Diagnostico.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconMaintenance: file(
      relativePath: { eq: "icons/Icone_Multi_Manutencao.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconCustom: file(
      relativePath: { eq: "icons/Icone_Multi_Personalizado.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconTraining: file(
      relativePath: { eq: "icons/Icone_Multi_Treinamento.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    logoMammut: file(relativePath: { eq: "logos/mammut.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 292)
      }
    }
    logoBeckmann: file(relativePath: { eq: "logos/beckmann.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 212)
      }
    }
    madeInGermany: file(relativePath: { eq: "logos/made-in-germany.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    bannerTop: file(relativePath: { eq: "banners/Banner_Home_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }
    elektroteks: file(
      relativePath: { eq: "banners/Banner_Elektroteks_news_port.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }
    elektroteksMobile: file(
      relativePath: { eq: "banners/Banner_Elektroteks_news_Mobile_port.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1080)
      }
    }
    bannerBottom: file(relativePath: { eq: "banners/Banner_2_home.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
  }
`
